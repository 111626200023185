<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Bill Number</th>
                    <th class="text-right">Bill Amount</th>
                    <th>Party Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in purchase" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.bill_number }}</td>
                    <td class="text-right"> {{ item.bill_amount }}</td>
                    <td> {{ item.party_name }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm me-1"
                                @click="goToEdit(item.id)"
                                style="cursor: pointer"
                        >
                            <i class="fas fa-eye"></i>
                        </button>
                        <button class="btn btn-primary btn-sm"
                                @click="goToPrint(item.id)"
                                style="cursor: pointer"
                        >
                            <i class="fas fa-print"></i>
                        </button>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!purchase.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pbPdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from "@/services/utils/day";

export default {  
    name: 'PurchaseListTable',
    data() {
        return{
            companyInfo: {},
            invoice: {},
            barcodeQuery: '',
            barcode: '',
            user: {},
            qrcode: ''
        }
    },
    props: {
        purchase: Array,
        route_name: {
            type: String,
            default: 'purchase-bill-edit'
        },
    },
    setup() {
        const {commaFormat} = figureFormatter()
        const { fetchSalesBill, ...rest } = handlePurchase();
        const {fetchCompanyInfo} = handleCompany();
        const { fetchBarcode } = handleBarcode();
        const { formatDate } = useDate()
        const store = useStore();
        const { exportToPDF } = pdfPrinter();

        return {
            commaFormat,
            fetchSalesBill,
            exportToPDF,
            fetchCompanyInfo,
            fetchBarcode,
            formatDate,
            store,
            rest
        }
    },
    mounted() {
         this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
         }).catch((error) => {
            console.log(error);
         });

         this.user = this.store.state.user.user
    },
    methods: {
        goToEdit(id) {
            let dynamicKeyName = (this.route_name === 'purchase-bill-edit') ? 'purchaseId' : 'salesId'
            let param = {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
            }
            param[dynamicKeyName] = id;
            this.$router.push({
                name: this.route_name,
                params: param,
                query: this.$route.query
            })
        },

        goToPrint(id) {
            const user = this.$store.state.user.user
            const companyQuery = `?company_id=${this.$route.params.companyId}`;
            let barcodeQuery = "";
            let qrcode = null
            let barcode = null
            let purchaseBill = null

            this.rest.fetchPurchaseBill(id, companyQuery).then( (res) =>{
                if( res ){
                    purchaseBill = res.data;
                    let qrcodeData = JSON.stringify({
                        ref_no: purchaseBill.bill_number,
                        ref_date: purchaseBill.date,
                        created_by: user.name,
                        created_date_time: this.formatDate(new Date())
                    })
                    barcodeQuery = `?code=${purchaseBill.bill_number}&qrcode=${qrcodeData}`;
                }}).then (() => {
                this.fetchBarcode(barcodeQuery).then( (res) => {
                    barcode = res.barcode;
                    qrcode = res.qrcode
                }).then( () => {
                    this.exportToPDF(this.companyInfo, purchaseBill, barcode, qrcode, user.name, 1)
                })
            }).catch( (err) => {
                console.log(err);
            })
        }
    }
}
</script>
